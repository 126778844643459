import React, { useEffect, useState, useRef } from "react";
// import { actionType } from "../Context/reducer";
// import { useStateValue } from "../Context/StateProvider";
import BassURl from "../Api/Api";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { addToCartAction, deleteFromCart } from "../Redux/Action/CartAction";
import CartItem from "../Cart/CartItem";
import NewNavbar from "./NewNavbar";

function Navbar() {
  let navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const addtocartreducer = useSelector((state) => state?.addToCartReducer);
  const { cartItems } = addtocartreducer;
  const dispatch = useDispatch();
  const [showCart, setShowCart] = useState(false);
  const onShow = () => setShowCart(true);

  const changeHeader = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeHeader);
  // / api call
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(1);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${BassURl}api/appliances-shop`);
        setProduct(response.data.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);
  // 2 api call for service tab

  const [services, setServices] = useState(null);

  useEffect(() => {
    axios.get(`${BassURl}/api/service-category`).then((response) => {
      setServices(response.data.data);
    });
  }, []);

  const captchaRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  // login function
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    // e.presist();
    // setLogin({ ...loginInput, [e.target.name]: e.target.value });
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };
  const loginSubmit = (e) => {
    e.preventDefault();
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios.post(`http://localhost:8000/api/login`, data).then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("auth_name", res.data.data.first_name);
        swal("Success", res.data.message, "success");
        navigate("/home");
      } else {
        // swal("Failed", res.data.data.message, "warning");
        console.log("worng");
      }
    });
  };

  const [isActive, setIsActive] = useState(false);
  const handledrop = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  const logout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
  const googleUser = JSON.parse(window.localStorage.getItem("googleUser"));

  //    decrpty data
  const [isHovering, setIsHovering] = useState(false);

  const [isHoveringService, setIsHoveringService] = useState(false);

  const handleServiceOver = () => {
    setIsHoveringService(true);
  };

  const handleServiceOut = () => {
    setIsHoveringService(false);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <>
      {/* Start Navigation */}
      {/* New Navabr */}
      <div className="classic-topHeader">
        <div className="top-header classicTopbar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-left">
                <div className="social-email left-brd d-inline-flex">
                  <ul className="list-inline social-icons d-inline-flex align-items-center">
                    <li className="list-inline-item">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Facebook"
                        aria-label="Facebook"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Linkedin"
                        aria-label="Linkedin"
                      >
                        <i className="fa-brands fa-linkedin" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title=""
                        data-bs-original-title="Instagram"
                        aria-label="Instagram"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                  </ul>
                  <div className="email d-none d-lg-block">
                    <a href="mailto:info@example.com">info@example.com</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center d-none d-md-block">
                Free shipping on all orders over $99
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-right d-flex align-items-center justify-content-end">
                <div className="phone-picker left-brd d-inline-flex align-items-center">
                  <div className="phone d-none d-lg-block">
                    <a href="tel:1234567890">123-456-7890</a>
                  </div>
                  <div className="select-wrap language-picker float-start">
                    <select
                      name=""
                      id=""
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "#fff",
                      }}
                    >
                      <option value="">English</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="header classicHeader d-flex align-items-center header-4 header-fixed">
          <div className="container-fluid">
            <div className="row">
              {/*Menu*/}
              <div className="col-1 col-sm-1 col-md-1 col-lg-5 align-self-center d-menu-col hdr-menu-left menu-position-left">
                <nav className="navigation" id="AccessibleNav">
                  <ul id="siteNav" className="site-nav medium left">
                    <li className="lvl1 parent dropdown">
                      <a href="#">Home</a>
                    </li>

                    <li className="lvl1 parent dropdown">
                      <a href="#">
                        Shop <i className="fa-solid fa-angle-down" />
                      </a>
                      <ul className="dropdown">
                        {services &&
                          services?.map((items, index) => {
                            return (
                              <>
                                <li style={{
                                      textTransform: "capitalize",
                                      color: "#000",
                                    }}>
                                  <a
                                    className="site-nav"
                                    href={`/service/${items?.service_url}`}
                                    key={index}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#000 !important",
                                    }}
                                  >
                                    {items?.service_title}
                                  </a>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
              {/*End Menu*/}
              {/*Logo*/}
              <div className="logo col-4 col-sm-4 col-md-4 col-lg-2 align-self-center">
                <a className="logoImg" href="index.html">
                  <img
                    className="mx-lg-auto default-logo"
                    src="/assets/img/logo.png"
                    title="Aquamart"
                    width={149}
                    height={39}
                  />
                  {/* <span className="logo-txt d-none">Hema</span> */}
                </a>
              </div>
              {/*End Logo*/}
              {/*Right Icon*/}
              <div className="col-8 col-sm-8 col-md-8 col-lg-5 align-self-center icons-col text-right">
                {/*Search*/}
                <div className="search-parent iconset">
                  <div className="site-search" title="Search">
                    <a
                      href="#;"
                      className="search-icon clr-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#search-drawer"
                    >
                      <i className="hdr-icon icon anm anm-search-l" />
                    </a>
                  </div>
                  <div
                    className="search-drawer offcanvas offcanvas-top"
                    tabIndex={-1}
                    id="search-drawer"
                  >
                    <div className="container">
                      <div className="search-header d-flex-center justify-content-between mb-3">
                        <h3 className="title m-0">What are you looking for?</h3>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      <div className="search-body">
                        <form
                          className="form minisearch"
                          id="header-search"
                          action="#"
                          method="get"
                        >
                          {/*Search Field*/}
                          <div className="d-flex searchField">
                            <div className="search-category">
                              <select className="rgsearch-category rounded-end-0">
                                <option value={0}>All Categories</option>
                                <option value={1}>- All</option>
                                <option value={2}>- Fashion</option>
                                <option value={3}>- Shoes</option>
                                <option value={4}>- Electronic</option>
                                <option value={5}>- Jewelry</option>
                                <option value={6}>- Vegetables</option>
                                <option value={7}>- Furniture</option>
                                <option value={8}>- Accessories</option>
                              </select>
                            </div>
                            <div className="input-box d-flex fl-1">
                              <input
                                type="text"
                                className="input-text border-start-0 border-end-0"
                                placeholder="Search for products..."
                                defaultValue=""
                              />
                              <button
                                type="submit"
                                className="action search d-flex-justify-center btn btn-primary rounded-start-0"
                              >
                                <i className="icon anm anm-search-l" />
                              </button>
                            </div>
                          </div>
                          {/*End Search Field*/}
                          {/*Search popular*/}
                          <div className="popular-searches d-flex-justify-center mt-3">
                            <span className="title fw-600">Trending Now:</span>
                            <div className="d-flex-wrap searches-items">
                              <a className="text-link ms-2" href="#">
                                Camera,
                              </a>
                              <a className="text-link ms-2" href="#">
                                Laptop,
                              </a>
                              <a className="text-link ms-2" href="#">
                                Electronic
                              </a>
                            </div>
                          </div>
                          {/*End Search popular*/}
                          {/*Search products*/}
                          <div className="search-products">
                            <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                              <li className="item empty w-100 text-center text-muted d-none">
                                You don't have any items in your search.
                              </li>
                              <li className="item">
                                <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                  <div className="mini-image text-center">
                                    <a
                                      className="item-link"
                                      href="product-layout1.html"
                                    >
                                      <img
                                        className="blur-up lazyloaded"
                                        data-src="assets/images/products/electronic-product1-120x170.jpg"
                                        src="assets/images/products/electronic-product1-120x170.jpg"
                                        alt="image"
                                        title="product"
                                        width={120}
                                        height={170}
                                      />
                                    </a>
                                  </div>
                                  <div className="ms-3 details text-left">
                                    <div className="product-name">
                                      <a
                                        className="item-title"
                                        href="product-layout1.html"
                                      >
                                        Digital SLR Camera
                                      </a>
                                    </div>
                                    <div className="product-price">
                                      <span className="old-price">$114.00</span>
                                      <span className="price">$99.00</span>
                                    </div>
                                    <div className="product-review d-flex align-items-center justify-content-start">
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star-o" />
                                      <i className="icon anm anm-star-o" />
                                      <span className="caption hidden ms-2">
                                        3 reviews
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="item">
                                <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                  <div className="mini-image text-center">
                                    <a
                                      className="item-link"
                                      href="product-layout1.html"
                                    >
                                      <img
                                        className="blur-up lazyloaded"
                                        data-src="assets/images/products/electronic-product2-120x170.jpg"
                                        src="assets/images/products/electronic-product2-120x170.jpg"
                                        alt="image"
                                        title="product"
                                        width={120}
                                        height={170}
                                      />
                                    </a>
                                  </div>
                                  <div className="ms-3 details text-left">
                                    <div className="product-name">
                                      <a
                                        className="item-title"
                                        href="product-layout1.html"
                                      >
                                        Instax Instant Camera
                                      </a>
                                    </div>
                                    <div className="product-price">
                                      <span className="price">$128.00</span>
                                    </div>
                                    <div className="product-review d-flex align-items-center justify-content-start">
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <span className="caption hidden ms-2">
                                        9 reviews
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="item">
                                <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                  <div className="mini-image text-center">
                                    <a
                                      className="item-link"
                                      href="product-layout1.html"
                                    >
                                      <img
                                        className="blur-up lazyloaded"
                                        data-src="assets/images/products/electronic-product3-120x170.jpg"
                                        src="assets/images/products/electronic-product3-120x170.jpg"
                                        alt="image"
                                        title="product"
                                        width={120}
                                        height={170}
                                      />
                                    </a>
                                  </div>
                                  <div className="ms-3 details text-left">
                                    <div className="product-name">
                                      <a
                                        className="item-title"
                                        href="product-layout1.html"
                                      >
                                        EOS Mirrorless Camera
                                      </a>
                                    </div>
                                    <div className="product-price">
                                      <span className="price">$99.00</span>
                                    </div>
                                    <div className="product-review d-flex align-items-center justify-content-start">
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star" />
                                      <i className="icon anm anm-star-o" />
                                      <span className="caption hidden ms-2">
                                        30 reviews
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/*End Search products*/}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Search*/}
                {/*Account*/}
                <div className="account-parent iconset">
                  <div className="account-link" title="Account">
                    <a href="">
                      <i className="fa-solid fa-user-tie" />
                    </a>
                  </div>
                  <div id="accountBox">
                    <div className="customer-links">
                      <ul className="m-0">
                        <li>
                          <a href="login.html">
                            <i class="icon fa-solid fa-right-to-bracket"></i>
                            Sign In
                          </a>
                        </li>
                        <li>
                          <a href="register.html">
                            <i className="icon fa-solid fa-user-plus" />
                            Register
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*End Account*/}
                {/*Wishlist*/}
                <div className="wishlist-link iconset" title="Wishlist">
                  <a href="wishlist-style1.html">
                    <i
                      className="fa-solid fa-cart-arrow-down"
                      style={{ color: "#fff" }}
                    />

                    <span className="wishlist-count">5</span>
                  </a>
                </div>
                {/*End Wishlist*/}
                {/*Minicart*/}
                <div className="header-cart iconset" title="Cart">
                  <a
                    href="#;"
                    className="header-cart btn-minicart clr-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#minicart-drawer"
                  >
                    <i
                      className="fa-solid fa-basket-shopping"
                      style={{ color: "#fff" }}
                    />

                    <span className="cart-count">2</span>
                  </a>
                </div>
                {/*End Minicart*/}
                {/*Mobile Toggle*/}
                <button
                  type="button"
                  className="iconset pe-0 menu-icon js-mobile-nav-toggle mobile-nav--open d-lg-none"
                  title="Menu"
                >
                  <i className="hdr-icon icon anm anm-times-l" />
                  <i className="hdr-icon icon anm anm-bars-r" />
                </button>
                {/*End Mobile Toggle*/}
              </div>
              {/*End Right Icon*/}
            </div>
          </div>
        </header>
      </div>

      {/* End Navigation */}

      {/* Log In Modal */}

      {/* End Modal */}
    </>
  );
}

export default Navbar;
