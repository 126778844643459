import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BassURl from "../Api/Api";
import Navbar from "../Navbar/Navbar";
// import ServiceAdd from '../Pages';
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import ApplianceAdd from "./ApplianceAdd";

function removeNonBreakingSpaces(inputString) {
  return inputString.replace(/&nbsp;/g, " ");
}

function AppliancesDetails() {
  const param = useParams();
  const [applianceByCategory, setApplianceByCategory] = useState("");
  useEffect(() => {
    axios
      .get(`${BassURl}api/product-by-category/${param.id}`)
      .then((res) => {
        setApplianceByCategory(res.data.data.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, []);
  console.log(applianceByCategory);

  const [filterValue, setFilterValue] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleFilterChange = (event) => {
    const filter = event.target.value;
    setFilterValue(filter);

    const filtered = applianceByCategory.filter((product) =>
      product.product_name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);

    const sorted = [...filteredProducts];
    if (event.target.value === "asc") {
      sorted.sort((a, b) => a.product_price - b.product_price);
    } else if (event.target.value === "desc") {
      sorted.sort((a, b) => b.product_price - a.product_price);
    } else if (event.target.value === "az") {
      sorted.sort((a, b) =>
        a.product_name.localeCompare(b.product_title, "en", {
          sensitivity: "base",
        })
      );
    } else if (event.target.value === "za") {
      sorted.sort((a, b) =>
        b.product_name.localeCompare(a.product_title, "en", {
          sensitivity: "base",
        })
      );
    }
    setFilteredProducts(sorted);
  };

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <>
      <Navbar />
      <div
        className="image-cover hero_banner "
        style={{ background: "url(/assets/img/banner/banner.webp) no-repeat" }}
        data-overlay={4}
      ></div>
      <section className="section-b-space shop-section">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-3 col-lg-2">
              <div
                className="left-box wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="shop-left-sidebar">
                  <div className="back-button">
                    {/* <h3>
                      <i className="fa-solid fa-arrow-left" /> Back
                    </h3> */}
                  </div>
                  <div className="accordions" id="accordionsExample">
                    <div className="accordions-item">
                      <div className="" id="headingOne">
                        <h2 className="accordions-header" id="headingThree">
                          <button
                            className="font-md title-color text-left accordions-button w-100"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Rating
                            <i className="float-right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                              >
                                <polyline points="9 18 15 12 9 6"></polyline>
                              </svg>
                            </i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordionsExample"
                      >
                        <div className="accordions-body">
                          <ul className="category-lists custom-padding">
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                />
                                <div className="form-check-label">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                  <span className="text-content">(5 Star)</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                />
                                <div className="form-check-label">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                  <span className="text-content">(4 Star)</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                />
                                <div className="form-check-label">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                  <span className="text-content">(3 Star)</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                />
                                <div className="form-check-label">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                  <span className="text-content">(2 Star)</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                />
                                <div className="form-check-label">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                  <span className="text-content">(1 Star)</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordions-item">
                      <div className="" id="headingTwo">
                        <h2 className="accordions-header" id="headingThree">
                          <button
                            className="font-md title-color text-left accordions-button w-100"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            Sort By
                            <i className="float-right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                              >
                                <polyline points="9 18 15 12 9 6"></polyline>
                              </svg>
                            </i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse show"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionsExample"
                      >
                        <div className="accordions-body">
                          <ul className="category-lists custom-padding custom-height">
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="radio"
                                  name="sortOrder"
                                  value="asc"
                                  checked={sortOrder === "asc"}
                                  onChange={handleSortChange}
                                  id="fruit"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="fruit"
                                >
                                  <span className="name">Low - High Price</span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="radio"
                                  name="sortOrder"
                                  value="desc"
                                  checked={sortOrder === "desc"}
                                  onChange={handleSortChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cake"
                                >
                                  <span className="name">High - Low Price</span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="radio"
                                  name="sortOrder"
                                  value="az"
                                  checked={sortOrder === "az"}
                                  onChange={handleSortChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="behe"
                                >
                                  <span className="name">A - Z Order</span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check ps-0 m-0 category-lists-box">
                                <input
                                  className="checkbox_animated"
                                  type="radio"
                                  name="sortOrder"
                                  value="za"
                                  checked={sortOrder === "za"}
                                  onChange={handleSortChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="snacks"
                                >
                                  <span className="name">Z - A Order</span>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordions-item">
                      <div className="" id="headingThree">
                        <h2 className="accordions-header" id="headingThree">
                          <button
                            className="font-md title-color text-left accordions-button w-100"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                          >
                            Price Range
                            <i className="float-right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                              >
                                <polyline points="9 18 15 12 9 6"></polyline>
                              </svg>
                            </i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse show"
                        aria-labelledby="headingThree"
                        data-parent="#accordionsExample"
                      >
                        <div className="accordions-body">
                          <div data-role="rangeslider">
                            <label htmlFor="price-min">Price:</label>
                            <input
                              type="range"
                              name="price-min"
                              id="price-min"
                              defaultValue={200}
                              min={0}
                              max={1000}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-lg-10">
              <div className="show-button">
                <div className="filter-button-group mt-0">
                  <div className="filter-button d-inline-block d-lg-none">
                    <a>
                      <i className="fa-solid fa-filter" /> Filter Menu
                    </a>
                  </div>
                </div>
                <div className="top-filter-menu"></div>
              </div>
              <div className="row product-list-section list-style">
                {applianceByCategory &&
                  applianceByCategory?.map((data) => {
                    return (
                      <>
                        <div className="col-lg-4">
                          <div className="property-listing property-2 productHeight items applicanceDivBorder">
                            <div className="listing-img-wrapper">
                              <div className="list-img-slide">
                                <div className="click">
                                  <div>
                                    <a href={`/product/${data?.product_url}`}>
                                      <img
                                        src={`${BassURl}${data?.product_image}`}
                                        className="img-fluid imgProduct mx-auto"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="listing-detail-wrapper">
                              <div className="listing-short-detail-wrap">
                                <div className="_card_list_flex">
                                  <div className="_card_flex_01">
                                    <h4 className="listing-name verified">
                                      <a
                                        href={`/product/${data?.product_url}`}
                                        className="prt-link-detail"
                                      >
                                        {data?.product_title}
                                      </a>
                                    </h4>
                                    <div className="proudctCardDes" style={{ color: "#fff" }}>
                                      {parse(
                                        removeNonBreakingSpaces(
                                          data?.product_description
                                        )
                                      )}
                                    </div>

                                    {/* <p className=""> </p> */}
                                  </div>
                                  <br />
                                </div>
                                <div
                                  className="footer-first"
                                  style={{
                                    display: "flex",
                                    marginTop: "12px",
                                  }}
                                >
                                  <div className="foot-rates">
                                    <span className="elio_rate good">4.2</span>
                                    <div className="_rate_stio">
                                      <i className="fa fa-star" />
                                      <i className="fa fa-star" />
                                      <i className="fa fa-star" />
                                      <i className="fa fa-star" />
                                      <i className="fa fa-star" />
                                    </div>
                                  </div>

                                  <div className="footer-first">
                                    <h6 className="listing-card-info-price mb-0  price-style">
                                      ₹ &nbsp;
                                      {formatNumberWithCommas(
                                        data?.product_price
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="listing-detail-footer">
                              <div className="footer-flex ">
                                <ApplianceAdd data={data} />
                                {/* <div className="prt-view" onClick={addtocart}>
              <i className="fa fa-shopping-cart" aria-hidden="true" />
              &nbsp; Add to cart
            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AppliancesDetails;
